import React from 'react'
import { DatePicker, theme, InputNumber, Popover, Space, Row, Col, Input, Button, Tooltip, message } from 'antd'
import TSTable from '../common/TSTable'
import * as _ from 'lodash';
import { URL_REPORT, URL_WEIGHT } from '../../constants/urls';
import { formatComma } from '../../frameworks/Util';
import {
  CalendarOutlined,
  CarOutlined,
  DashboardOutlined,
  EditOutlined,
  SearchOutlined,
  UserOutlined,
  SwapOutlined,
  ShoppingCartOutlined,
  ExportOutlined,
  FileTextOutlined,
  HistoryOutlined,
  PercentageOutlined,
  DollarOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { DATE_FORMAT, HUMAN_DATETIME_2_DIGIT_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT, INPUT_TYPE, WEIGHT_TICKET_DIRECTION, DATETIME_FORMAT } from '../../constants/string';
import { PATH } from '../../routes/CustomRoutes';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ColorButton from '../common/ColorButton';
import { GET, POST } from '../../frameworks/HttpClient';
import ErrorMessages from '../common/ErrorMessages';
import IncomingList from '../scale/IncomingList';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import CustomCardList from '../common/CustomCardList';
import { CYAN_COLOR, ORANGE_COLOR } from '../../constants/color';
import CustomerListModal from '../scale/CustomerListModal';
import ScaleCardHistoryModal from '../scale/ScaleCardHistoryModal';
import ReportButton from '../common/ReportButton';
import Cookies from "js-cookie";
import ScaleCardEditDRCModal from './ScaleCardEditDRCModal';
import ScaleCardEditPaymentModal from './ScaleCardEditPaymentModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function ScaleCardView() {
  const navigate = useNavigate();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const cardRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const [search, setSearch] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState(null);

  const { token: { colorSuccess, colorInfo, colorWarning } } = theme.useToken();

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const [editDRCOpen, setEditDRCOpen] = React.useState(null);
  const [editPaidAmountOpen, setEditPaidAmountOpen] = React.useState(null);
  const [editValue, setEditValue] = React.useState(null);
  // Handle customer list modal
  const [direction, setDirection] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [target, setTarget] = React.useState(null);
  const [editDRCTarget, setEditDRCTarget] = React.useState(null);
  const [editPaymentTarget, setEditPaymentTarget] = React.useState(null);
  const cashierID = Cookies.get('id')
  const { t } = useTranslation();
  const key = 'updatable';

  const handleOpenEditDetail = (typeOpen=() => {}, value, initialValue) => {
    typeOpen(value)
    setEditValue(initialValue)
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("pick_up_and_delivery_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("pick_up_and_delivery_list"),
        subtitle:  `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename:`${t("pick_up_and_delivery_list"," -")} ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const MANUAL_TICKET_DIRECTION_TRANSLATION = {
    [WEIGHT_TICKET_DIRECTION.BUY]: t("receipt_of_goods"),
    [WEIGHT_TICKET_DIRECTION.SELL]: t("delivery_of_goods"),
  }

  const columns = [
    { title: t("date"), dataIndex: 'created', render: (value) => moment(value).format(HUMAN_DATETIME_2_DIGIT_FORMAT) },
    { title: t("scale_number"), dataIndex: 'code', searcher: true, },
    { title: t("customer_name"), dataIndex: 'customer_name', searcher: true, },
    { title: t("menu.product"), dataIndex: "product_name" },
    { 
      title: t("ticket_type"), 
      dataIndex: 'direction', 
      render: (value, original) => (value 
        ? (original.input_type === INPUT_TYPE.MANUAL) 
        ? MANUAL_TICKET_DIRECTION_TRANSLATION[value] 
        : MANUAL_TICKET_DIRECTION_TRANSLATION[value]
        : "-"),
        renderReport: (value) => (value),
      searcher: true,
      searcherOptions: [
        { key: 'all', value: null, label: t("all") },
        { key: 'sell', value: 'sell', label: t("delivery_of_goods") },
        { key: 'buy', value: 'buy', label: t("receipt_of_goods") },
      ],
    },
    { title: t("dashboard.gross_weight"), dataIndex: 'net_weight', render: (value) => formatComma(value) },
    { title: t("amount_of_money"), dataIndex: 'price', render: (value) => formatComma(value) },
    { title: '%DRC', dataIndex: 'extra_details', renderReport: (value) => (formatComma(_.get(value, 'drc', 0))), render: (value, original) => (value && value.hasOwnProperty('drc')) || _.get(original, 'need_drc', false) ? (
      <div style={{ textAlign: 'center' }}>
        {formatComma(_.get(value, 'drc', 0))}
        <Popover
          content={(
            <Space.Compact>
              <InputNumber max={100} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} value={editValue} onChange={(value) => setEditValue(value)}/>
              <ColorButton
                type='primary'
                loading={loading}
                override={colorWarning}
                icon={<EditOutlined/>}
                onClick={() => handleForceUpdate(original.id, 'drc')}>
                {t("actions.edit")}
              </ColorButton>
            </Space.Compact>
          )}
          title= {t("edit_DRC")}
          placement='left'
          trigger='click'
          open={editDRCOpen == original.id}
          onOpenChange={(open) => handleOpenEditDetail(setEditDRCOpen, open ? original.id : null, _.get(value, 'drc', 0))}
        >
          <ColorButton
            type='link'
            size='small'
            override={colorInfo}
            icon={<EditOutlined/>}
            onClick={() => handleOpenEditDetail(setEditDRCOpen, original.id, _.get(value, 'drc', 0))} />
        </Popover>
      </div>
    ) : <div style={{ textAlign: 'center' }}>-</div> },
    { title: t("amount_paid"), dataIndex: 'paid_amount', renderReport: (value) => (value), render: (value, original) => original.is_paid ? (
      <div style={{ textAlign: 'center' }}>
        {formatComma(value)}
        <Popover
          content={(
            <Space.Compact>
              <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} value={editValue} onChange={(value) => setEditValue(value)}/>
              <ColorButton
                type='primary'
                loading={loading}
                override={colorWarning}
                icon={<EditOutlined/>}
                onClick={() => handleForceUpdate(original.id, 'paid_amount')}>
                {t("actions.edit")}
              </ColorButton>
            </Space.Compact>
          )}
          title= {t("edit_the_amount_paid")}
          placement='left'
          trigger='click'
          open={editPaidAmountOpen == original.id}
          onOpenChange={(open) => handleOpenEditDetail(setEditPaidAmountOpen, open ? original.id : null, value)}
        >
          <ColorButton type='link' size='small' icon={<EditOutlined/>} onClick={() => handleOpenEditDetail(setEditPaidAmountOpen, original.id, value)} />
        </Popover>
      </div>
    ) : <div style={{ textAlign: 'center' }}>-</div> },
    {
      title: 'Action',
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => (
        <div style={{ textAlign: 'center' }}>
          <Space>
          {contextHolder}
            <Tooltip title={t("actions.edit")}>
              <ColorButton 
                type={"primary"}
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => window.open(`${PATH.SCALE}/${value}`)} />
            </Tooltip>
            <Tooltip title= {t("edit_history")}>
              <ColorButton 
                type={"primary"}
                override={colorSuccess}
                icon={<HistoryOutlined />}
                onClick={() => setTarget(value)} />
            </Tooltip>
            {(original.direction === WEIGHT_TICKET_DIRECTION.BUY) && (
              <Tooltip title= {t("print_receipt")}>
                <ReportButton
                  type='primary'
                  override={CYAN_COLOR}
                  icon={<PrinterOutlined />}
                  url={`${URL_REPORT.RECEIPT_REPORT}?ticket=${original.id}&export=pdf`}
                />
              </Tooltip>
            )}
            {original.del_ref && (
              <Tooltip title= {t("factory_del.print")}>
                  <ReportButton
                    type='primary'
                    override={colorInfo}
                    icon={<FileTextOutlined />}
                    url={`${URL_REPORT.DEL_REPORT}?del001=${original.del_ref}&export=pdf`}
                  />
              </Tooltip>
            )}
          </Space>
        </div>
      )
    }
  ]

  const details = {
    title : { dataIndex: 'customer_name'},
    header: { title : ' - ', dataIndex: 'code', icon: <UserOutlined style={{ color: colorInfo }}/> },
    descriptions: [
      {
        title: t("date"), dataIndex: 'created', icon: <CalendarOutlined/>,
        render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT)
      },
      { title: t("vehicle_registration_number"), dataIndex: 'vehicle_license_plate', icon: <CarOutlined/> },
      { title: t("ticket_type"), dataIndex: 'direction', icon: <SwapOutlined />,
        render: (value) => _.get(MANUAL_TICKET_DIRECTION_TRANSLATION, value, '-')
      },
      { title: t("dashboard.gross_weight"), dataIndex: 'net_weight', icon: <DashboardOutlined />,
        render: (value) => `${formatComma(value)}`
      },
      { title: 'DRC%', dataIndex: 'extra_details.drc', icon: <PercentageOutlined />,
        render: (value) => `${formatComma(value)} %`
      },
      { title: t("amount_paid"), dataIndex: 'paid_amount', icon: <DollarOutlined />,
        render: (value, original) => original.is_paid ? `${formatComma(value)} ` : "-"
      },
    ],
    onClick: (item) => navigate(`${PATH.SCALE}/${item.id}`),
  }

  const handleForceUpdate = async (ticket, field_name) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(URL_WEIGHT.TICKET_FORCE_UPDATE, {
        ticket, field_name, new_value: editValue, cashier : cashierID,
      });
      setEditPaidAmountOpen(false);
      setEditDRCOpen(false);
      if (tableRef.current) {
        tableRef.current.fetchWithoutResetPage();
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
    if (cardRef.current) {
      cardRef.current.fetchData();
    }
  }

  const handleDocumentDEL = async (id) => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
  
    try {
      const response = await GET(`${URL_REPORT.DEL_REPORT}?del001=${id}&export=pdf`);
      window.open(response.requestUrl);
      setTimeout(() => {
        messageApi.open({
          key,
          type: 'success',
          content: 'Loaded!',
          duration: 2,
        });
      }, 2000);
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  };

  return (
    <div style={{ marginBottom : 36}}>
      { !isOnMobile ? <IncomingList/> :
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: 'auto',
          padding: '0 8px',
          border: '1px solid rgba(140, 140, 140, 0.35)',
          marginBottom : '10px'
        }}
      >
        <IncomingList/>
      </div>}
      <Row gutter={[16, 16]} wrap>
        <Col xl={4} lg={6} md={12} xs={24}>
          <DatePicker format={HUMAN_DATE_FORMAT} onChange={(value) => setDateFilter(value)} placeholder={t("select_date")} style={{ width: '100%' }} />  
        </Col>
        <Col lg={8} md={12} xs={24}>
          <Space.Compact style={{ width: '100%' }}>
            <Input placeholder={t("search")} onPressEnter={fetchData} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
            <Button loading={loading} icon={<SearchOutlined/>} onClick={fetchData}>{t("search")}</Button>
          </Space.Compact>
        </Col>

        { !isOnMobile && (
          <Col xl={12} lg={10} md={12}>
            <Space style={{ float: 'right' }}>
              <ColorButton
                icon={<FilePdfOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("pdf")}
              >
                PDF
              </ColorButton>
              <ColorButton
                icon={<FileExcelOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("excel")}>
                Excel
              </ColorButton>
              <ColorButton
                type='primary'
                override={colorSuccess}
                icon={<ShoppingCartOutlined />}
                onClick={() => setDirection(WEIGHT_TICKET_DIRECTION.BUY)}>
                {t("receipt_of_goods")}
              </ColorButton>
              <ColorButton
                type='primary'
                override={ORANGE_COLOR}
                icon={<ExportOutlined />}
                onClick={() => setDirection(WEIGHT_TICKET_DIRECTION.SELL)}>
                {t("delivery_of_goods")}
              </ColorButton>
            </Space>
          </Col>
        ) }
      </Row>

        {isOnMobile && (
            <Row gutter={[16,16]} style={{ marginTop : 16}}>
              <Col xl={12} lg={10} md={12} xs={12}>
                <ColorButton
                  type='primary'
                  style={{ width : '100%', height : (isOnMobile ? 45 : 'auto')}}
                  override={colorSuccess}
                  icon={<ShoppingCartOutlined />}
                  onClick={() => setDirection(WEIGHT_TICKET_DIRECTION.BUY)}>
                  {t("receipt_of_goods")}
                </ColorButton>
              </Col>
              <Col xl={12} lg={10} md={12} xs={12}>
              <ColorButton
                  type='primary'
                  style={{ width : '100%', height : (isOnMobile ? 45 : 'auto')}}
                  override={colorWarning}
                  icon={<ExportOutlined />}
                  onClick={() => setDirection(WEIGHT_TICKET_DIRECTION.SELL)}>
                  {t("delivery_of_goods")}
                </ColorButton>
              </Col>
            </Row>
          )}

      <ErrorMessages message={errorMessages}/>
      <div style={{ marginTop: '1rem' }}>
        { isOnMobile ? (
          <CustomCardList
            ref={cardRef}
            details={details}
            url={URL_WEIGHT.WEIGHT_TICKET}
            params={{ date: dateFilter ? dateFilter.format(DATE_FORMAT) : null, search }}
            pagination={false}
            infiniteScroll = {true}
            pageSize={10}
            // buttonTitle={
            //   {
            //     icon : <HistoryOutlined />,
            //     text : "ประวัติ",
            //     onClick : (e) => setTarget(e),
            //   }
            // }
            buttonExtra={
              [
                {
                  icon : <EditOutlined />,
                  text : "DRC",
                  onClick : (e) => setEditDRCTarget(e),
                  color : colorWarning,
                },
                {
                  icon : <EditOutlined />,
                  text : t("payment_amount"),
                  onClick : (e) => setEditPaymentTarget(e),
                  color : colorWarning,
                  condition : (e) => (e.is_paid === true)
                }
              ]
            }
            footer
            />
        ) : (
          
          <TSTable
            ref={tableRef}
            columns={columns}
            url={`${URL_WEIGHT.WEIGHT_TICKET}`}
            params={{ date: dateFilter ? dateFilter.format(DATE_FORMAT) : null, search }}
            rowKey='id'
            // onRow={(record, rowIndex) => ({
            //   onClick: _ => navigate(`${PATH.SCALE}/${record.id}/`)
            // })}
            />
        ) }
      </div>

      <CustomerListModal
        open={direction ? true : false}
        direction={direction}
        onClose={() => setDirection(null)}
        onSelected={(customerID) => {
          navigate({
            pathname: `${PATH.SCALE}/add/`,
            search: createSearchParams({
              direction: direction,
              input_type: INPUT_TYPE.MANUAL,
              customer: customerID,
            }).toString()
          })
        }}/>

      <ScaleCardHistoryModal
        open={target ? true : false}
        onClose={() => setTarget(null)} 
        target={target}
      />

      <ScaleCardEditDRCModal
        open={editDRCTarget ? true : false}
        onClose={() => setEditDRCTarget(null)} 
        onUpdate={() => {
          setEditDRCTarget(null)
          fetchData()
        }}
        target={editDRCTarget}
      />

      <ScaleCardEditPaymentModal
        open={editPaymentTarget ? true : false}
        onClose={() => 
          setEditPaymentTarget(null)
        }
        onUpdate={() => {
          setEditPaymentTarget(null)
          fetchData()
        }}
        target={editPaymentTarget}
        
      />
    </div>
  )
}