import { Card, message, Pagination, Space, List, theme, Tooltip, Popconfirm, Row, Col } from 'antd';
import React from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, FileDoneOutlined, FilePdfOutlined, FileTextOutlined, FileExcelOutlined} from '@ant-design/icons';
import TSTable from '../common/TSTable';
import { URL_DELIVERY, URL_REPORT } from '../../constants/urls';
import moment from 'moment';
import { DATETIME_FORMAT, DATE_FORMAT, HUMAN_DATETIME_FORMAT, HUMAN_DATE_FORMAT, LANGUAGE } from '../../constants/string';
import ColorButton from '../common/ColorButton';
import { GET, DELETE } from '../../frameworks/HttpClient';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import Meta from 'antd/es/card/Meta';
import ReportButton from '../common/ReportButton';
import { formatComma } from '../../frameworks/Util';
import { CYAN_COLOR, SECONDARY_COLOR } from '../../constants/color';
import DeliveryModal from '../delivery/DeliveryModal';
import { useUserContext } from '../../hooks/UserSettingProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import dayjs from 'dayjs';
import FactoryDeliveryModal from './FactoryDeliveryModal';

export default function FactoryDeliveryOrderView (props) {
  const { token: { colorSuccess, colorInfo, colorWarning, colorPrimary } } = theme.useToken();

  const tableRef = React.useRef(null);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [dataDel ,setDataDel] = React.useState([]);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1)
  const [openDELModal, setOpenDELModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [targetDEL, setTargetDEL] = React.useState(null);
  const [detailTarget, setDetailTarget] = React.useState(null);
  const { t, i18n } = useTranslation();
  const key = 'updatable';

  const fetchDataDEL = async(page) => {
    const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}?page_size=5&page=${page ? page : 1}`)
    setDataDel(response.data.results)
    setTotal(response.data.total)
  } 

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  React.useEffect(()=>{
    fetchDataDEL();
  },[])

const handleDocumentDEL = async (id) => {
  messageApi.open({
    key,
    type: 'loading',
    content: 'Loading...',
  });
  try {
    const response = await GET(`${URL_REPORT.DEL_FACTORY_REPORT}?del001=${id}&export=pdf`);
    window.open(response.requestUrl);
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'Loaded!',
        duration: 2,
      });
    }, 2000);
  } catch (error) {
    console.error('Error loading PDF:', error);
  }
};

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("menu.delivery_order"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("menu.delivery_order"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("delivery_order")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      refreshData();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }

  }

  const columns = [
    {
      title: t("factory_del.del_number"),
      dataIndex: 'code',
      sorter: true,
      searcher: true,
    },
    {
      title: t("factory_del.ri_number"),
      dataIndex: 'ri_no',
      searcher: true,
    },
    {
      title: t("factory_del.ac_fl_number"),
      dataIndex: 'acfl_no',
      searcher: true,
    },
    {
      title: t("factory_del.factory_arrival_date"),
      dataIndex: 'receive_date',
      searcher: true,
      dateSearcher: true,
      render: (value) => moment(value, DATE_FORMAT).format(HUMAN_DATE_FORMAT),
    },
    {
      title: t("factory_del.collecting_center_name"),
      dataIndex: i18n.language === LANGUAGE.EN ? 'sender_name_en' : 'sender_name',
      searcher: true,
    },
    {
      title: t("factory_del.product"),
      dataIndex: 'product_name',
    },
    {
      title: t("factory_del.weight_from_collecting_center"),
      dataIndex: 'sent_weight',
      render: (value) => formatComma(value)
    },
    {
      title: t("factory_del.status"),
      dataIndex: 'status_detail',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      skipReport: true,
      render: (value) => (
        <div style={{ textAlign: 'center' }}>
          <Space>
           {contextHolder}
              <Tooltip title='แก้ไข'>
                <ColorButton
                  type='primary'
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => navigate(`${PATH.DELIVERY_ORDER}/${value}`)} />
              </Tooltip>
            <Tooltip title={t("factory_del.print")}>
                <ReportButton
                override={colorInfo}
                type='primary'
                icon={<FileTextOutlined/>}
                url={`${URL_REPORT.DEL_FACTORY_REPORT}?del001=${value}&export=pdf`}
                />
            </Tooltip>
            <Tooltip title={t("plantations.detail")}>
              <ColorButton
                override={CYAN_COLOR}
                type='primary'
                icon={<FileDoneOutlined />}
                onClick={() => setDetailTarget(value)}/>
            </Tooltip>
              <Tooltip title={t("actions.delete_data")}>
                <Popconfirm
                  title={t("confirm_cancellation_of_the_rubber_delivery")}
                  onConfirm={() => handleDelete(value)}
                  okText={t("yes")}
                  okButtonProps={{ danger: true }}
                  cancelText={t("no")}>
                  <ColorButton
                    type='primary'
                    danger
                    icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
          </Space>
        </div>
      )
    }
  ]

  return (
    <div>
      {/* <Tabs
        size='large'
        items={[
          { key: 'progressing', label: <span><ClockCircleOutlined/>รอรับสินค้า</span>, children: '' },
          { key: 'all', label: <span><FileTextOutlined />ทั้งหมด</span>, children: '' },
        ]}/> */}
        <ColorButton
          type='primary'
          override={SECONDARY_COLOR}
          icon={<PlusCircleOutlined />}
          style={{ float: 'right', marginBottom: '1rem', marginLeft: 8 }}
          onClick={() => setOpenDELModal(true)}>
          {t("delivery.add_del")}
        </ColorButton>
    {isOnMobile ? (
      <div>
        <List 
          dataSource={dataDel}
          renderItem={(item) => (
            <List.Item>
                  <Card 
                    title={<p>DEL{item.code}</p>}
                    style={{width:'100%'}}
                    actions={[
                      <FileDoneOutlined onClick={()=>handleDocumentDEL(item.id)} />
                    ]}>
                    <Meta 
                    description={
                      <>
                      <>{t("type")}: {item.ri_no}</>
                      <br/>
                      <>{t("farmers.phone")}: {item.acfl_no}</>
                      <br/>
                      <>{t("factory_del.factory_arrival_date")}: {item.receive_date}</>
                      <br/>
                      <>{t("factory_del.collecting_center_name")}: {item.sender_name}</>
                      <br/>
                      <>{t("product")}: {item.product_name}</>
                      <br/>
                      <>{t("status")}: {item.status_detail}</>
                      <br/>
                      {/* <>สถานะ: {item.status && item.status!=='none' ? FARMER_STATUS[item.status] : "ไม่ทราบ"}</> */}
                      </>
                    }/>
            </Card>   
            </List.Item>
          )}>
        </List>
         <div style={{textAlign:'center'}}>
            <Pagination
                defaultPageSize={5}
                defaultCurrent={1}
                current={page}
                total={total}
                onChange={(page) => {
                  setPage(page)
                  fetchDataDEL(page)
                }}
              />
            </div>
      </div>
    ):(<>
      <Space style={{ float: 'right' }}>
        <Row gutter={8}>
        <Col>
          <ColorButton
            icon={<FilePdfOutlined />}
            style={{float: "right"}}
            onClick={() => exportTable("pdf")}>
            PDF
          </ColorButton>
        </Col>
        <Col>
          <ColorButton
            icon={<FileExcelOutlined />}
            style={{float: "right", marginBottom : 16}}
            onClick={() => exportTable("excel")}>
            Excel
          </ColorButton>
        </Col>
        </Row>  
      </Space>
      <TSTable
        columns={columns}
        ref={tableRef}
        url={URL_DELIVERY.DELIVERY_TRANSACTION}
        rowKey={'id'}
        size={'small'}
        pageSize={10}/>
      </>
    )}

      <DeliveryModal
        open={openDELModal || (targetDEL != null)}
        data={targetDEL}
        factory={user && user.factory}
        onClose={() => {
          if (openDELModal)
            setOpenDELModal(false)

          if (targetDEL)
            setTargetDEL(null);
        }}
        onUpdated={() => {
          if (openDELModal)
            setOpenDELModal(false)

          if (targetDEL)
            setTargetDEL(null);

          refreshData();
        }} />
      
      <FactoryDeliveryModal                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       yModal
        open={detailTarget != null}
        target={detailTarget}
        onClose={() => {
          refreshData()
          setDetailTarget(null)
      }}/>
    </div>
  )
}