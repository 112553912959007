import React from 'react'
import propTypes from "prop-types";
import { Alert, AutoComplete, Checkbox, Col, DatePicker, Form, Input, Modal,  Row,  Select,  theme } from "antd";
import Header from '../common/Header';
import { GET, POST, PUT } from '../../frameworks/HttpClient';
import { DATE_FORMAT, HUMAN_DATE_FORMAT } from '../../constants/string';
import _ from "lodash";
import CustomSearchSelect from '../common/CustomSearchSelect';
import { URL_CUSTOMER, URL_DELIVERY, URL_MEMBER } from '../../constants/urls';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function DeliveryModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [drivers, setDrivers] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [sender, setSender] = React.useState(null);
  const [certificateOptions, setCertificateOptions] = React.useState(null);
  const [sentDate, setSentDate] = React.useState([]);
  const [arrivalDate, setArrivaDate] = React.useState([]);
  const [needFSCCertificate, setNeedFSCCertificate] = React.useState(false);
  const { t, i18n } = useTranslation();

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const disabledSentDate = (current) => {
    return current && (current <= sentDate || current < dayjs().startOf('day'));
  };
  const disabledArrivalDate = (current) => {
    return current && (current <= arrivalDate || current < dayjs().startOf('day') || current <= sentDate);
  };

  const handleSentDateChange = (sentDate) => {
    setSentDate(sentDate)
    setArrivaDate(null)
    form.resetFields(['receive_date']); 
    
  };
  const handleDelivery = async () => {
    setErrorMessages(null);
    setLoading(true);
    try {
      const data = await form.validateFields();
      if (data['receive_date'])
        data['receive_date'] = data.receive_date.format(DATE_FORMAT)
      if (data['sent_date'])
        data['sent_date'] = data.sent_date.format(DATE_FORMAT)
      if (!props.data) {
        await POST(URL_DELIVERY.DELIVERY_TRANSACTION, data)
      } else {
        await PUT(`${URL_DELIVERY.DELIVERY_TRANSACTION}${props.data.id}/`, data)
      }
      props.onUpdated();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const onSearchDriver = async (search) => {
    setLoading(true)
    try {
      const response = await GET(URL_CUSTOMER.DRIVER, { cc: sender, search });
      setDrivers(_.get(response, 'data.results', []).map(driver => ({
        ...driver,
        value: _.get(driver, 'full_name'),
        label: `${_.get(driver, 'full_name')} (${t("phone_number")} : ${_.get(driver, 'phone', '-')})`,
      })))
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const onSearchVehicle = async (search) => {
    setLoading(true)
    try {
      const response = await GET(URL_CUSTOMER.VEHICLE, { cc: sender, search });
      setVehicles(_.get(response, 'data.results', []).map(vehicle => ({
        value: _.get(vehicle, 'license_plate'),
        label: _.get(vehicle, 'license_plate'),
      })))
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const fetchCertificateOptions = async () => {
    try {
      const response = await GET(URL_DELIVERY.DELIVERY_CERTIFICATE);
      setCertificateOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.code,
          value: item.code
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (sender) {
      onSearchDriver();
      onSearchVehicle();
    }
  }, [sender])
  
  React.useEffect(() => {
    if (props.open) {
      setNeedFSCCertificate(false)
      fetchCertificateOptions();
      setDrivers([]);
      const data = props.data
      if (data){
        form.setFieldsValue({
          ...data, 
          receive_date: data.receive_date ? dayjs(data.receive_date, DATE_FORMAT) : null,
          sent_date: data.sent_date ? dayjs(data.sent_date, DATE_FORMAT) : null,
        })
      }
      if (props.factory) 
        form.setFieldValue('receiver', props.factory)
    } else {
      setErrorMessages(null);
      form.resetFields();
    }
  }, [props.open])

  return (
    <Modal
      width={760}
      okText={props.target ? t("edit") : t("add")}
      okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      cancelText={t("cancel")}
      open={props.open}
      confirmLoading={loading}
      onOk={handleDelivery}
      onCancel={props.onClose}>
        <Header title={t("del")}></Header>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
        
        <Form 
          form={form}
          style={{marginTop: 16}}
          layout="vertical"
          autoComplete="off"
          initialValues={{ is_agriac_supply_chain: props.factory ? false : true }}>
          <Row gutter={16}>
            <Col md={16} sm={24} xs={24}>
              <Form.Item name='product' label={t("product")} rules={[{ required: true }]}>
                <CustomSearchSelect
                  url={URL_CUSTOMER.MASTER_PRODUCT}
                  handleChange={option => {
                    setNeedFSCCertificate(option.traceability === "fsc")
                  }}
                  label='name'/>
              </Form.Item>
            </Col>

            {needFSCCertificate && (!props.factory) &&
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='certificate' label='FSC Certificate' rules={[{ required: false }]}>
                  <Select options={certificateOptions} />
                </Form.Item>
              </Col>
            }

            {needFSCCertificate && (props.factory) &&
              <Col md={8} sm={24} xs={24}>
                <Form.Item name='certificate' label='FSC Certificate' rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            }

            <Col md={16} sm={24} xs={24}>
              <Form.Item name='sender' label={t("collecting_center")} rules={[{ required: true }]}>
                <CustomSearchSelect
                  url={URL_MEMBER.COLLECTING_CENTER}
                  label='name'
                  filterOption={false}
                  handleChange={option => {
                    setSender(_.get(option, 'id', null))
                  }}/>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='sent_date' label={t("export_date")} rules={[{ required: true }]}>
                <DatePicker
                  // disabledDate={disabledSentDate}
                  onChange={handleSentDateChange}
                  style={{ width: '100%' }}
                  format={HUMAN_DATE_FORMAT}/>
              </Form.Item>
            </Col>
            <Col md={16} sm={24} xs={24}>
              <Form.Item name='receiver' label={t("factory")} rules={[{ required: true }]}>
                <CustomSearchSelect
                  readOnly={(props.factory) ? true : false}
                  url={URL_MEMBER.FACTORY}
                  params={{page_size: 999}}
                  label='name' />
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='receive_date' label={t("arrival_date")} rules={[{ required: true }]}>
                <DatePicker
                  // disabledDate={disabledArrivalDate}
                  onChange={(arrivalDate)=>{
                    setArrivaDate(arrivalDate)
                  }}
                  style={{ width: '100%' }}
                  format={HUMAN_DATE_FORMAT}/>
              </Form.Item>
            </Col>
            
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='vehicle_license_plate' label={t("vehicle_registration_number")}>
                <AutoComplete options={vehicles} onSearch={(value) => onSearchVehicle(value)}>
                  <Input/>
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='driver_name' label={t("driver")}>
                <AutoComplete
                  options={drivers}
                  onSearch={(value) => onSearchDriver(value)}
                  onSelect={(__, option) => {
                    console.log(option)
                    form.setFieldValue('driver_phone', _.get(option, 'phone', ''))
                  }}>
                  <Input/>
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Form.Item name='driver_phone' label={t("phone_number")}>
                <Input/>
              </Form.Item>
            </Col>

            <Col md={16} sm={24} xs={24}>
              <Form.Item 
                name={"is_agriac_supply_chain"} 
                valuePropName="checked"
                hidden={props.factory}>
                <Checkbox>{t("delivery.this_delivery_note_is_issued_on_behalf_of_agriac")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
    </Modal>
  )
}
DeliveryModal.defaultProps = {
  open: false,
  data: null,
  factory: null,
  onUpdated: () => {},
  onClose: () => {},
}

DeliveryModal.propTypes = {
  open: propTypes.bool,
  data: propTypes.object,
  factory: propTypes.object,
  onUpdated: propTypes.func,
  onClose: propTypes.func,
}